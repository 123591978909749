import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

export default {
  state() {
    return {
      token: localStorage.getItem("token") || sessionStorage.getItem("token") || "",
      userName: localStorage.getItem("userName") || "",
    };
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
