import Vue from "vue";
import Vuex from "vuex";
import Auth from "./modules/Auth";
import Produces from "./modules/Produces";
import Wastes from "./modules/Wastes";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Auth,
    Produces,
    Wastes
  },
});
