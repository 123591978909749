export default {
  setToken(state, token){
    state.token = token;
    console.log("Mutation:" + state.token)
  },

  setUserName(state, userName){
    state.userName = userName;
    console.log("UserName: " + state.userName);
  },

  removeToken(state){
    state.token = "";
    state.userName = "";
  },
}