import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/HomePageView.vue"),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "",
    name: "sideBar",
    component: () => import(/* webpackChunkName: "SideBarLayout" */ "../layouts/SideBarLayout.vue"),
    children: [
      {
        path: "/register",
        name: "register",
        component: () => import(/* webpackChunkName: "RegisterView" */ "../views/RegisterView.vue" ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/database",
        name: "database",
        component: () => import(/* webpackChunkName: "DatabaseView" */ "../views/DatabaseView.vue" ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/collusions",
        name: "collusions",
        component: () => import(/* webpackChunkName: "CollusionsView" */ "../views/CollusionsView.vue" ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/profile",
        name: "userProfile",
        component: () => import(/* webpackChunkName: "UserProfileView" */ "../views/UserProfileView.vue" ),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "",
    name: "formLayout",
    component: () => import(/* webpackChunkName: "FormLayout" */ "../layouts/FormLayout.vue"),
    children: [
      {
        path: "/auth/login",
        name: "login",
        component: () => import(/* webpackChunkName: "LoginView" */ "../views/LoginView.vue" ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/forgot-password",
        name: "forgottenPassword",
        component: () => import(/* webpackChunkName: "ForgottenPasswordView" */ "../views/ForgottenPasswordView.vue" ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/auth/password/reset",
        name: "resetPassword",
        component: () => import(/* webpackChunkName: "ResetPasswordView" */ "../views/ResetPasswordView.vue" ),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "*",
        name: "NotFound",
        component: () => import(/* webpackChunkName: "NotFoundView" */ "../views/NotFoundView.vue" ),
        meta: {
          requiresAuth: false,
        },
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(function (to, from, next) {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next("/auth/login");
  } else {
    next();
  }
});

export default router;
