export default {
  // saves the token to localStorage
  loginWithLocal(context, token){
    localStorage.setItem("token", token);
    context.commit("setToken", token);
  },

  // saves the token to sessionStorage
  loginNoLocal(context, token){
    sessionStorage.setItem("token", token);
    context.commit("setToken", token);
  },

    // saves the userName to localStorage
  saveUserName(context, userName){
    localStorage.setItem("userName", userName);
    context.commit("setUserName", userName);
  },

  // removes the userName and token from local- or sessionStorage
  logout(context){
    if(localStorage.getItem("token") !== null){
      localStorage.removeItem("token");
      localStorage.removeItem("userName");
    }else if(sessionStorage.getItem("token") !== null){
      sessionStorage.removeItem("token");
    }
    context.commit("removeToken");
  },
}