import mutations from "./mutations"
import actions from "./actions"

export default {
  state () {
    return{
      produces: ""
    }
  },
  mutations: mutations,
  actions: actions,
}