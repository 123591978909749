import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import preset from "./defaultPreset/preset/index";

import el from "vuetify/lib/locale/el.js";

Vue.use(Vuetify);

export default new Vuetify({
  preset,
  icons: {
    iconfont: "mdi",
  },
  theme: {
    options: {
      customProperties: true,
      variations: true, // creates all the shades
    },
  },
  lang: {
    locales: {
      el,
    },
    current: "el",
  },
});
