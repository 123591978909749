require("./overrides.scss");
export default {
  theme: {
    themes: {
      light: {
        primary: "#58585A",
        secondary: "#FFD8A9",
        accent: "#F5B77D",
        info: "#FDEEDC",
        success: "#4E9F3D",
        warning: "#F1592A",
        error: "#eb4d4b",
        sidebar: "#FFB862",
        selected: "#FE7A00"
      },
    },
  },
};

//c18954
